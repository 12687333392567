import React, { Component } from 'react'
import { Table, Dropdown, Popup, Icon, Button, Image } from 'semantic-ui-react'
import classNames from 'classnames'
import _ from 'lodash'
import Pagination from './Pagination'

const defaultProps = {
  defaultItemsPerPage: 5,
  defaultCurrentPage: 1,
  selectable: false,
  rowKey: 'key',
}

class MediaTable extends Component {
  state = {
    itemsPerPage: 5,
    currentPage: 1,
    sort: {},
    pageCount: 1,
    items: [],
  }

  items = null
  itemsPerPage = 1
  currentPage = 1
  sort = {}
  pages = null

  constructor(props) {
    super(props)

    this.items = props.items
    this.itemsPerPage = _.defaultTo(props.itemsPerPage, props.defaultItemsPerPage)
    this.currentPage = _.defaultTo(props.currentPage, props.defaultCurrentPage)
    this.sort = _.get(props, 'sort', {})

    _.extend(this.state, {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      sort: this.sort,
    })
  }

  UNSAFE_componentWillMount() {
    this.setState({ items: this.items })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.items, this.props.items)) {
      this.items = nextProps.items
      this.setState({ items: this.items })
    }

    if (!_.isEqual(nextProps.itemsPerPage, this.props.itemsPerPage)) {
      this.itemsPerPage = nextProps.itemsPerPage
      this.setState({ itemsPerPage: this.itemsPerPage })
    }

    if (!_.isEqual(nextProps.currentPage, this.props.currentPage)) {
      this.currentPage = nextProps.currentPage
      this.setState({ currentPage: this.currentPage })
    }

    if (!_.isEqual(nextProps.sort, this.props.sort)) {
      this.sort = nextProps.sort
      this.setState({ sort: this.sort })
    }
  }

  handleItemsPerPageDropdownChange = (event, { value }) => {
    this.itemsPerPage = value

    if (this.props.onPageChange) {
      this.props.onPageChange(event, {
        ...this.props,
        itemsPerPage: this.itemsPerPage,
      })
    }
  }

  handlePaginationChange = (event, { currentPage }) => {
    this.currentPage = currentPage

    this.setState({
      currentPage: this.currentPage,
    })

    if (this.props.onPageChange) {
      this.props.onPageChange(event, {
        ...this.props,
        currentPage: this.currentPage,
      })
    }
  }

  handleHeaderCellClick = (event, { field, defaultSortOrder }) => {
    if (_.isNil(field)) {
      return
    }

    if (!_.isNil(this.sort[field])) {
      this.sort[field] = this.sort[field] === 'asc' ? 'desc' : 'asc'
    } else {
      _.unset(this.sort, _.keys(this.sort))
      this.sort[field] = _.isNil(defaultSortOrder) ? 'asc' : 'desc'
    }

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(event, {
        ...this.props,
        sort: this.sort,
        isHeaderCellClick: true,
      })
    }
  }

  render() {
    const isItemsEmpty = _.isEmpty(this.props.items)

    const tableHeaderCellsAttributes = [
      {
        label: 'ID',
        align: 'center',
        field: 'id',
      },
      {
        label: 'メディア名',
        field: 'name',
      },
      {
        label: 'ロゴ画像',
      },
      {
        label: 'Site',
      },
      {
        label: 'CP',
        field: 'cpId',
      },
      {
        label: '表示優先度',
        field: 'displayPriority',
        align: 'center',
      },
      { label: 'メディアの設定' },
      { label: '公開設定' },
      { label: 'Shannon連携' },
      { label: '設定', align: 'center' },
    ]

    const buttonGroup = item => (
      <Button.Group secondary>
        <Button
          disabled={!this.props.hasUpdatePermission}
          icon="edit"
          onClick={() => {
            this.props.openEditModal(item)
          }}
        />

        {!_.isEqual(this.props.viewStatus, 'media_trash') && (
          <Button
            disabled={!this.props.hasDeletePermission}
            icon="trash alternate outline"
            onClick={() => {
              this.props.openRemoveModal(item)
            }}
          />
        )}

        {_.isEqual(this.props.viewStatus, 'media_trash') && (
          <Button
            disabled={!this.props.hasRestorePermission}
            icon="undo"
            onClick={() => {
              this.props.openUndoModal(item)
            }}
          />
        )}
      </Button.Group>
    )

    return (
      <Table
        celled
        structured
        unstackable
        collapsing={this.props.collapsing}
        padded={this.props.padded}
        compact={this.props.compact}
        size={this.props.size}
        className={classNames({ sortable: !isItemsEmpty })}
      >
        <Table.Header>
          <Table.Row>
            {tableHeaderCellsAttributes.map((item, index) => (
              <Table.HeaderCell
                key={index}
                textAlign={item.align}
                collapsing={_.defaultTo(item.collapsing, true)}
                className={classNames({
                  sorted: this.state.sort[item.field],
                  ascending: this.state.sort[item.field] === 'asc',
                  descending: this.state.sort[item.field] === 'desc',
                  disabled: _.isNil(item.field),
                })}
                style={{ minWidth: item.minWidth }}
                onClick={event => this.handleHeaderCellClick(event, item)}
              >
                {item.label}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!_.isEmpty(this.state.items) &&
            this.state.items.map(item => {
              const tableRows = _.slice(item.mediumItems, 1).map(mediumItem => (
                <Table.Row>
                  <Table.Cell>{mediumItem.name}</Table.Cell>
                  <Table.Cell>{mediumItem.autoPublish ? 'オート' : 'マニュアル'}</Table.Cell>
                  <Table.Cell>{mediumItem.shannonSubmissionAllowed ? '連携' : '非連携'}</Table.Cell>
                </Table.Row>
              ))

              const rowSpan = item.mediumItems.length > 0 ? item.mediumItems.length : 1

              const headMediumItem = _.head(item.mediumItems)

              return [
                <Table.Row key={item[this.props.rowKey]} style={{ borderTop: '4px solid #ccc' }}>
                  <Table.Cell rowSpan={rowSpan}>{item.id}</Table.Cell>
                  <Table.Cell rowSpan={rowSpan}>{item.name}</Table.Cell>
                  <Table.Cell rowSpan={rowSpan}>
                    <Image src={item.logo} centered size="tiny" />
                  </Table.Cell>
                  <Table.Cell rowSpan={rowSpan}>
                    {_.get(item, 'site') && (
                      <a href={_.get(item, 'site')} target="_blank" rel="noopener noreferrer">
                        外部サイト
                        <Icon name="external" />
                      </a>
                    )}
                  </Table.Cell>
                  <Table.Cell rowSpan={rowSpan}>{_.get(item, 'cp.name')}</Table.Cell>
                  <Table.Cell rowSpan={rowSpan} textAlign="center">
                    {_.get(item, 'displayPriority')}
                  </Table.Cell>
                  <Table.Cell>{_.get(headMediumItem, 'name')}</Table.Cell>
                  <Table.Cell>
                    {!_.isNil(headMediumItem) ? (
                      <span>{_.get(headMediumItem, 'autoPublish') ? 'オート' : 'マニュアル'}</span>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {!_.isNil(headMediumItem) ? (
                      <span>{_.get(headMediumItem, 'shannonSubmissionAllowed') ? '連携' : '非連携'}</span>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center" rowSpan={rowSpan}>
                    {buttonGroup(item)}
                  </Table.Cell>
                </Table.Row>,

                ...tableRows,
              ]
            })}
        </Table.Body>
        {!_.isUndefined(this.props.totalPages) && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={10}>
                <Popup
                  position="right center"
                  inverted
                  content="１ページあたりの表示件数"
                  trigger={
                    <Dropdown
                      compact
                      selection
                      className="upward"
                      options={[
                        { text: '5', value: 5 },
                        { text: '10', value: 10 },
                        { text: '25', value: 25 },
                        { text: '50', value: 50 },
                        { text: '100', value: 100 },
                      ]}
                      value={this.state.itemsPerPage}
                      disabled={isItemsEmpty}
                      onChange={this.handleItemsPerPageDropdownChange}
                    />
                  }
                />

                <Pagination
                  floated="right"
                  pageCount={this.props.totalPages}
                  currentPage={this.state.currentPage}
                  onChange={this.handlePaginationChange}
                  disabled={isItemsEmpty}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    )
  }
}

MediaTable.defaultProps = defaultProps

export default MediaTable
