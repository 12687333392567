import React, { Component } from 'react'
import {
  Modal,
  Dimmer,
  Loader,
  Segment,
  Button,
  Grid,
  Label,
  Popup,
  Checkbox,
  Divider,
  Message,
} from 'semantic-ui-react'
import { Form, Input } from 'formsy-semantic-ui-react'
import { MatomeApi } from 'trill-api-admin-client'
import TextareaAutosize from 'react-textarea-autosize'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import Store from 'store'
import classNames from 'classnames'

import ApiErrorMessage from './ApiErrorMessage'
import FormErrorLabel from './FormErrorLabel'
import ArticleDataTable from './ArticleDataTable'
import MatomeAddArticleModal from './MatomeAddArticleModal'
import MediumInput from '../components/MediumInput'
import CategoriesDropdown from '../components/CategoriesDropdown'
import MediaDropdown from '../components/MediaDropdown'
import CancelablePromise from '../CancelablePromise'
import LogLevel from '../LogLevel'
import { flattenObject } from '../util'
import { PUBLISH_DATETIME_LABEL_FORMAT } from '../constants/date_format'

const logger = LogLevel.getLogger('MatomeEditModal')
const matomeApi = new MatomeApi()
let getMatome
let sendMatome
let deleteMatome
let getMatomeArticles
const putMatomeArticles = []

const propTypes = {
  /**
   * 編集対象の特集 (作成の場合は空)
   */
  matome: PropTypes.object,

  /**
   * モーダルの表示状態
   */
  open: PropTypes.bool,

  /**
   * モーダルを閉じたときに呼び出す外部関数
   */
  onClose: PropTypes.func,

  /**
   * 記事編集を押したときに呼び出す外部関数
   */
  onArticleEdit: PropTypes.func,

  /**
   * データの更新に成功したとき
   */
  onSuccessDataChanged: PropTypes.func,
}

const defaultPropTypes = {
  matome: {},
  open: false,
}

class MatomeEditModal extends Component {
  state = {
    isBusy: false,
    isFormModified: false,
    isPublishDatetimeModified: false,
    isFormValid: false,
    isFormSearchValid: false,
    thumbnailImageUrlInputValue: '',
    thumbnailImageError: null,
    coverImageUrlInputValue: '',
    coverImageError: null,
    isCoverImageActive: false,
    publishDatetime: moment(),
    description: '',
    isDescriptionValid: true,
    isDescriptionModified: false,
    // 特集に紐づいている記事テーブル表示用
    tableData: {
      isBusy: false,
      articles: [],
      totalPages: 0,
      totalItems: 0,
      currentPage: 1,
      itemsPerPage: 5,
      sorting: { publishDatetime: 'desc' },
      filtering: {
        title: '',
        categoryId: null,
        mediumId: null,
      },
    },
    totalRelatedArticles: 0,
    isRelatedArticlesModified: true,
    isHiddenArticlesTable: true,
    isAddModalOpen: false,
    isDeleteModalOpen: false,
    deleteArticle: null,
    apiError: null,
    matomeArticleApiError: null,
  }

  /**
   * 初期のフォーム入力データ
   */
  initialFormValues = {}

  /**
   * 記事の編集を押して画面遷移する前のフォーム入力データ
   * ブラウザバックで戻ったときに入力中のフォームデータを復元するために使用
   */
  previousFormValues = {}

  /**
   * フォームの入力データが初期化されてるかどうか
   */
  isFormResetted = false

  UNSAFE_componentWillMount() {
    // 特集に関連する記事の編集ボタンを押したときに保存した状態があれば、その状態で復元
    const dataState = Store.get('matomeDataTableState')

    if (dataState) {
      const formValues = dataState.previousFormValues

      this.setState({
        matome: dataState.matome,
        thumbnailImageUrlInputValue: _.get(formValues, 'thumbnail.image.url'),
        coverImageUrlInputValue: _.get(formValues, 'cover.image.url'),
        publishDatetime: moment(dataState.publishDatetime),
        tableData: dataState.tableData,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.matome, nextProps.matome)) {
      const thumbnailImageUrlInputValue = _.get(nextProps, 'matome.thumbnail.image.url', '')
      const coverImageUrlInputValue = _.get(nextProps, 'matome.cover.image.url', '')

      // 初期化
      this.setState(
        {
          isFormModified: false,
          isPublishDatetimeModified: false,
          thumbnailImageUrlInputValue,
          coverImageUrlInputValue,
          publishDatetime: moment(_.get(nextProps, 'matome.publishDatetime'), ''),
          matome: nextProps.matome,
          isCoverImageActive: !_.isEqual(thumbnailImageUrlInputValue, coverImageUrlInputValue),
          tableData: {
            isBusy: false,
            articles: [],
            totalPages: 0,
            totalItems: 0,
            currentPage: 1,
            itemsPerPage: 5,
            sorting: { publishDatetime: 'desc' },
            filtering: {
              title: '',
              categoryId: null,
              mediumId: null,
            },
          },
          totalRelatedArticles: 0,
          isRelatedArticlesModified: true,
          isBusy: true,
        },
        () => {
          // 特集に関連づけられている記事を取得
          this.retrieveMatomeArticles()
            .then(() => {
              this.setState({
                isBusy: false,
                isHiddenArticlesTable: _.size(this.state.tableData.articles) === 0,
              })
            })
            .catch(error => {
              logger.error('retrieve matome articles error', error)

              this.setState({ isBusy: false })
            })
        },
      )

      this.isFormResetted = false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-line
    if (this.refs.form && !this.isFormResetted) {
      if (this.state.matome) {
        // フォームの情報を初期化する
        this.initializeFormValues(this.state.matome)

        // 特集に関連する記事の編集ボタンを押したときに保存した状態があれば、その状態で復元
        const dataState = Store.get('matomeDataTableState')
        let formValues = this.initialFormValues
        if (dataState) {
          // フォームの初期化は保存時のものにする
          formValues = dataState.previousFormValues
        } else {
          // フォーム情報のリセット
          this.previousFormValues = this.initialFormValues
        }
        // reformat publishDatetime before reseting
        const resetValues = _.assign({}, formValues, {
          publishDatetime: formValues.publishDatetime.format(PUBLISH_DATETIME_LABEL_FORMAT),
        })
        this.refs.form.reset(resetValues)
      } else {
        // 新規作成の場合
        this.refs.form.reset({})
      }

      this.isFormResetted = true
    }
  }

  componentWillUnmount() {
    // eslint-disable-line
    if (!_.isNil(getMatome)) {
      getMatome.cancel()
    }
    if (!_.isNil(sendMatome)) {
      sendMatome.cancel()
    }
    if (!_.isNil(deleteMatome)) {
      deleteMatome.cancel()
    }
    if (!_.isEmpty(putMatomeArticles)) {
      _.each(putMatomeArticles, putMatomeArticle => putMatomeArticle.cancel())
      putMatomeArticles.length = 0
    }
  }

  /**
   * フォームの値を変更したときのハンドラ
   */
  handleFormChange = (currentValues, isChanged) => {
    // eslint-disable-line
    const partialState = { isFormModified: false }
    const flattenCurrentValues = flattenObject(currentValues)

    // 現在のフォームデータを初期のフォームデータと比較
    _.each(flattenCurrentValues, (value, key) => {
      if (this.initialFormValues[key] !== value) {
        partialState.isFormModified = true
      }
    })

    this.previousFormValues = currentValues

    this.setState(partialState)
  }

  /**
   * フォームの値が妥当なときに呼び出されるハンドラ
   */
  handleFormValid = () => {
    this.setState({ isFormValid: true })
  }

  /**
   * フォームの値が無効のときに呼び出されるハンドラ
   */
  handleFormInvalid = () => {
    this.setState({ isFormValid: false })
  }

  /**
   * フォームの値を送信したときのハンドラ
   */
  handleFormValidSubmit = (submitFormData, resetForm) => {
    this.setState({
      isBusy: true,
      apiError: null,
    })
    const formData = { ...submitFormData, description: this.state.description }

    sendMatome = CancelablePromise(this.sendData(this.getRequestParameters(formData)))
    sendMatome.promise
      .then(matomeResponse => {
        const matome = matomeResponse.data

        if (this.props.onSuccessDataChanged) {
          this.props.onSuccessDataChanged()
        }

        this.initializeFormValues(matome)

        const formValues = this.initialFormValues
        // reformat publishDatetime before reseting
        resetForm(
          _.assign({}, formValues, {
            publishDatetime: formValues.publishDatetime.format(PUBLISH_DATETIME_LABEL_FORMAT),
          }),
        )

        this.setState({
          isFormModified: false,
          isPublishDatetimeModified: false,
          matome,
          isBusy: false,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error('send data error', error)

        this.setState({
          isBusy: false,
          apiError: error,
        })
      })
  }

  /**
   * Handler to be called when the form value search is valid
   */
  handleFormSearchValid = () => {
    this.setState({ isFormSearchValid: true })
  }

  /**
   * Handler to be called when the form value search is invalid
   */
  handleFormSearchInValid = () => {
    this.setState({ isFormSearchValid: false })
  }

  /**
   * 閉じるボタンを押したときのハンドラ
   * Store に保存した state をリセットする
   */
  handleModalClose = () => {
    if (this.props.onClose) {
      // 保存したテーブルの状態をリセット
      Store.remove('matomeDataTableState')
      this.props.onClose()
    }

    this.resetDescription()
  }

  /**
   * 保存・更新ボタンを押したときのハンドラ
   */
  handleSaveButtonClick = () => {
    this.refs.form.submit()
  }

  /**
   * サムネイル画像を変更したときのハンドラ
   */
  handleThumbnailImageInputChange = (event, { mediumUrl, error }) => {
    const partialState = {
      thumbnailImageUrlInputValue: _.defaultTo(mediumUrl, ''),
      thumbnailImageError: error,
    }

    this.setState(partialState)
  }

  /**
   * カバー画像を変更したときのハンドラ
   */
  handleCoverImageInputChange = (event, { mediumUrl, error }) => {
    const partialState = {
      coverImageUrlInputValue: _.defaultTo(mediumUrl, ''),
      coverImageError: error,
    }

    this.setState(partialState)
  }

  /**
   * カバー画像を別のものにするトグル操作のハンドラ
   */
  handleCoverImageToggleChange = (event, { checked }) => {
    this.setState({ isCoverImageActive: checked })
  }

  /**
   * 公開日付を更新したときのハンドラ
   */
  handleDatePickerEvent = (event, picker) => {
    if (event.type === 'apply') {
      const isPublishDatetimeModified = !picker.startDate.isSame(this.initialFormValues.publishDatetime)

      this.setState({
        publishDatetime: picker.startDate,
        isPublishDatetimeModified,
      })
    }
  }

  /**
   * 紐づいている記事一覧テーブルから記事編集ボタンを押したときのハンドラ
   * バックキーで状態の復元を行いたいため Store に state を保存する
   */
  handleArticleEditButtonClick = article => {
    // 記事編集画面から戻ってきたときに関連テーブルの状態を戻すために状態を保存
    const dataState = {
      previousFormValues: this.previousFormValues,
      publishDatetime: this.state.publishDatetime,
      matome: this.state.matome,
      tableData: this.state.tableData,
    }

    Store.set('matomeDataTableState', dataState)

    if (this.props.onArticleEdit) {
      this.props.onArticleEdit(article)
    }
  }

  /**
   * 紐付け済み記事一覧のデータテーブルの並び替えハンドラ
   */
  handleMatomeArticleDataTableSelectionChange = (event, { sort }) => {
    const tableData = this.state.tableData
    tableData.sorting = sort

    this.setState({ tableData }, () => {
      this.retrieveMatomeArticles()
    })
  }

  /**
   * 紐付け済み記事一覧のデータテーブルのページ情報を更新したときのハンドラ
   */
  handleMatomeArticleDataTablePageChange = (event, { currentPage, itemsPerPage }) => {
    const tableData = this.state.tableData
    tableData.currentPage = currentPage
    tableData.itemsPerPage = itemsPerPage

    this.setState({ tableData }, () => {
      this.retrieveMatomeArticles()
    })
  }

  /**
   * 関連記事削除用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleDeleteModalCancelButtonClick = () => {
    this.setState({
      deleteArticle: null,
      isDeleteModalOpen: false,
    })
  }

  /**
   * 関連記事追加用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleAddModalCancel = () => {
    this.setState({ isAddModalOpen: false })
    this.resetDescription()
  }

  /**
   * 記事追加用モーダルの追加ボタンを押したときのハンドラ
   */
  handleAddModalAdd = addArticleIds => {
    const tableData = this.state.tableData
    tableData.isBusy = true

    this.setState(
      {
        tableData,
        // タグがなかった場合、テーブルにローディングを出せないため全体のローディングを表示
        isBusy: _.size(this.state.tableData.articles) === 0,
        matomeArticleApiError: null,
      },
      () => {
        const matomeId = this.state.matome.id

        putMatomeArticles.length = 0
        _.each(addArticleIds, articleId => {
          putMatomeArticles.push(
            CancelablePromise(
              new Promise((resolve, reject) => {
                matomeApi
                  .putMatomeArticle(matomeId, articleId)
                  .then(() => {
                    resolve()
                  })
                  .catch(error => {
                    if (error.isCanceled) {
                      reject(error)
                      return
                    }

                    logger.error(`put matome article  matomeId #${matomeId} articleId #${articleId} error`, error)

                    // 紐付け済みの記事が選択された場合もエラーになるが、その場合は処理を続行したいため resolve
                    // 上記の理由: 紐付け済み記事も紐付け可能な記事として表示するため (紐付け済み記事が増えた場合に紐付け記事を除外するのに時間がかかるため)
                    resolve()
                  })
              }),
            ),
          )
        })

        Promise.all(_.map(putMatomeArticles, putMatomeArticle => putMatomeArticle.promise))
          .then(() => {
            this.setState({ isRelatedArticlesModified: true }, () => this.retrieveMatomeArticles())
          })
          .then(() => {
            this.setState({
              isBusy: false,
              isAddModalOpen: false,
              isHiddenArticlesTable: false,
            })
          })
          .catch(error => {
            logger.debug(`put matome articles matomeId #${matomeId} error`, error)

            tableData.isBusy = false

            this.setState({
              isBusy: false,
              tableData,
              matomeArticleApiError: error,
            })
          })
      },
    )
  }

  /**
   * 記事削除用モーダルの削除ボタンを押したときのハンドラ
   */
  handleDeleteModalApproveButtonClick = () => {
    const matome = this.state.matome
    const deleteArticle = this.state.deleteArticle

    const tableData = this.state.tableData
    tableData.isBusy = true

    this.setState({
      tableData,
      matomeArticleApiError: null,
    })

    deleteMatome = CancelablePromise(matomeApi.deleteMatomeArticle(matome.id, deleteArticle.id))
    deleteMatome.promise
      .then(() => {
        this.setState({ isRelatedArticlesModified: true }, () => this.retrieveMatomeArticles())
      })
      .then(() => {
        this.setState({
          deleteArticle: null,
          isDeleteModalOpen: false,
          isHiddenArticlesTable: _.size(this.state.tableData.articles) === 0,
        })
      })
      .catch(error => {
        if (error.isCanceled) {
          return
        }

        logger.error(`delete matome article matomeId #${matome.id} deleteArticleId #${deleteArticle.id} error`, error)

        tableData.isBusy = false

        this.setState({
          tableData,
          matomeArticleApiError: error,
        })
      })
  }

  /**
   * 記事削除用モーダルのキャンセルボタンを押したときのハンドラ
   */
  handleDeleteModalCancelButtonClick = () => {
    this.setState({
      deleteArticle: null,
      isDeleteModalOpen: false,
    })
  }

  /**
   * 紐付け済み記事一覧をタイトルで検索するための値を変更したときのハンドラ
   */
  handleTitleInputChange = (event, data) => {
    const tableData = this.state.tableData
    tableData.filtering.title = data.value

    this.setState({ tableData })
  }

  /**
   * 紐付け済み記事をカテゴリで検索するためのドロップダウンの値を変更したときのハンドラ
   */
  handleCatgoriesDropdownChange = (event, { value }) => {
    const tableData = this.state.tableData
    tableData.filtering.categoryId = value

    this.setState({ tableData }, () => {
      this.retrieveMatomeArticles()
    })
  }

  /**
   * 紐付け済み記事をメディアで検索するためのドロップダウンの値を変更したときのハンドラ
   */
  handleMediaDropdownChange = (event, { value }) => {
    const tableData = this.state.tableData
    tableData.filtering.mediumId = value

    this.setState({ tableData }, () => {
      this.retrieveMatomeArticles()
    })
  }

  /**
   * 絞り込みボタンを押したときのハンドラ
   */
  handleSearchButtonClick = () => {
    this.retrieveMatomeArticles()
  }

  /**
   * Handler to be called when the description is changed
   */
  handleDescriptionChange = e => {
    const description = e.target.value
    let isDescriptionModified = false

    if (!_.isEqual(this.initialFormValues.description, description)) {
      isDescriptionModified = true
    }

    this.setState({ description, isDescriptionValid: !_.isEmpty(description), isDescriptionModified })
  }

  /**
   * 関連記事追加用モーダルを開くボタンを押したときの関数
   */
  openArticleAddModal() {
    this.setState({ isAddModalOpen: true })
  }

  /**
   * タグ削除用モーダルを開く関数
   */
  openDeleteModal(article) {
    this.setState({
      isDeleteModalOpen: true,
      deleteArticle: article,
    })
  }

  /**
   * API にデータを送信
   */
  sendData = matome => {
    if (_.isEmpty(this.state.matome)) {
      return matomeApi.postMatome(matome)
    }

    const matomeId = this.state.matome.id

    return matomeApi.patchMatome(matomeId, { matomeUpdateValues: matome })
  }

  /**
   * reset state description
   */
  resetDescription = () => {
    this.setState({ description: '', isDescriptionValid: true, isDescriptionModified: false })
  }

  /**
   * フォームの初期化
   * @param {Object} matome - まとめデータ
   */
  initializeFormValues(matome) {
    const description = matome.description
    this.initialFormValues.title = matome.title
    this.initialFormValues.description = description
    this.initialFormValues.slug = matome.slug
    this.initialFormValues['thumbnail.image.url'] = _.get(matome, 'thumbnail.image.url', '')
    this.initialFormValues['thumbnail.image.copyright.title'] = _.get(matome, 'thumbnail.image.copyright.title', '')
    this.initialFormValues['thumbnail.image.copyright.url'] = _.get(matome, 'thumbnail.image.copyright.url', '')
    this.initialFormValues['cover.image.url'] = _.get(matome, 'cover.image.url', '')
    this.initialFormValues['cover.image.copyright.title'] = _.get(matome, 'cover.image.copyright.title', '')
    this.initialFormValues['cover.image.copyright.url'] = _.get(matome, 'cover.image.copyright.url', '')
    this.initialFormValues.publishDatetime = moment(matome.publishDatetime)

    this.setState({ description })

    logger.debug('initialize form', matome, this.initialFormValues)
  }

  /**
   * 紐付け済み記事一覧の取得
   */
  retrieveMatomeArticles() {
    return new Promise((resolve, reject) => {
      if (_.isEmpty(this.state.matome)) {
        resolve()
        return
      }

      const matomeId = this.state.matome.id
      const tableData = this.state.tableData
      tableData.isBusy = true

      this.setState({
        tableData,
        matomeArticleApiError: null,
      })

      if (!_.isNil(getMatomeArticles)) {
        getMatomeArticles.cancel()
      }
      getMatomeArticles = CancelablePromise(matomeApi.getMatomeArticles(matomeId, this.getRequestQuery()))
      getMatomeArticles.promise
        .then(response => {
          tableData.isBusy = false
          tableData.articles = response.data
          const responseHeader = response.header
          tableData.currentPage = parseInt(_.get(responseHeader, 'pagination-currentpage', 1), 10)
          tableData.itemsPerPage = parseInt(_.get(responseHeader, 'pagination-itemsperpage', 5), 10)
          tableData.totalPages = parseInt(_.get(responseHeader, 'pagination-totalpages', 0), 10)
          tableData.totalItems = parseInt(_.get(responseHeader, 'pagination-totalitems', 0), 10)

          const partialState = { tableData }

          if (this.state.isRelatedArticlesModified) {
            // 合計関連記事数を設定 (追加時も変更したいため保持している記事数よりも API から取得した合計数が大きい場合は更新)
            partialState.totalRelatedArticles = tableData.totalItems
            partialState.isRelatedArticlesModified = false
          }

          this.setState(partialState, resolve())
        })
        .catch(error => {
          logger.error(`retrieve matome articles matome id ${matomeId} error`, error)

          tableData.isBusy = false
          tableData.currentPage = 1
          tableData.itemsPerPage = 5
          tableData.totalPages = 0
          tableData.totalItems = 0

          this.setState(
            {
              tableData,
              matomeArticleApiError: error,
            },
            () => reject(error),
          )
        })
    })
  }

  /**
   * API に送信するパラメータを取得
   */
  getRequestParameters = submitFormData => {
    // 差分を取得する関数
    const difference = (object, base) => {
      const changes = (_object, _base) =>
        _.transform(_object, (result, value, key) => {
          if (!_.isEqual(value, _base[key])) {
            result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
          }
        })
      return changes(object, base)
    }

    // 変更前のフォームの値
    const initialFormValues = this.initialFormValues
    const initialData = {}
    initialData.title = initialFormValues.title
    initialData.description = initialFormValues.description
    initialData.slug = initialFormValues.slug
    initialData.publishDatetime = initialFormValues.publishDatetime.format(PUBLISH_DATETIME_LABEL_FORMAT)

    // フォームに入っている値がドット記法のためオブジェクトに変換
    // 画像 url のみ default 値として state として空文字を設定しているため default 値として '' を指定
    _.set(initialData, 'thumbnail.image.url', _.get(initialFormValues, 'thumbnail.image.url', ''))
    _.set(initialData, 'thumbnail.image.copyright.title', _.get(initialFormValues, 'thumbnail.image.copyright.title'))
    _.set(initialData, 'thumbnail.image.copyright.url', _.get(initialFormValues, 'thumbnail.image.copyright.url'))
    _.set(initialData, 'cover.image.url', _.get(initialFormValues, 'cover.image.url', ''))
    _.set(initialData, 'cover.image.copyright.title', _.get(initialFormValues, 'cover.image.copyright.title'))
    _.set(initialData, 'cover.image.copyright.url', _.get(initialFormValues, 'cover.image.copyright.url'))

    //  カバー画像を設定しない場合はサムネイル画像と同じものを送る
    if (!this.state.isCoverImageActive) {
      submitFormData.cover.image = submitFormData.thumbnail.image
    }

    // フォームから送られてきたデータと初期データの差分を取得
    const requestParameters = difference(submitFormData, initialData)

    // 差分データに公開日時が入っていた場合
    if (!_.isEmpty(requestParameters.publishDatetime)) {
      _.extend(requestParameters, {
        publishDatetime: this.state.publishDatetime.toISOString(),
      })
    }

    const publishDatetime = this.state.publishDatetime
    if (!publishDatetime.isSame(moment(this.initialFormValues.publishDatetime))) {
      // 日付に変更があった場合
      requestParameters.publishDatetime = publishDatetime.toISOString()
    }

    // slug に空文字に設定されていた場合はリセット
    if (_.isEqual(requestParameters.slug, '')) {
      requestParameters.slug = ' '
    }

    // サムネイル画像の出典元に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.title'), '')) {
      _.set(requestParameters, 'thumbnail.image.copyright.url', ' ')
    }

    // サムネイル画像の出典元の URL に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'thumbnail.image.copyright.url'), '')) {
      _.set(requestParameters, 'thumbnail.image.copyright.url', ' ')
    }

    // カバー画像の出典元に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.title'), '')) {
      _.set(requestParameters, 'cover.image.copyright.title', ' ')
    }

    // カバー画像の出典元の URL に空文字に設定されていた場合はリセット
    if (_.isEqual(_.get(requestParameters, 'cover.image.copyright.url'), '')) {
      _.set(requestParameters, 'cover.image.copyright.url', ' ')
    }

    logger.debug('get request parameters', {
      requestParameters,
      initialData,
      submitFormData,
    })

    return _.omitBy(requestParameters, v => !_.isBoolean(v) && !_.isNumber(v) && _.isEmpty(v))
  }

  /**
   * API 通信時のリクエストクエリを取得
   */
  getRequestQuery = () => {
    const tableData = this.state.tableData
    // 合計データ数を設定中の tableData.itemsPerPage で割って合計ページを算出
    const totalPage = Math.ceil(tableData.totalItems / tableData.itemsPerPage)
    // 算出した合計ページが取得予定のページを超えていた場合、最後のページを表示
    const currentPage = totalPage > 0 && tableData.currentPage > totalPage ? totalPage : tableData.currentPage
    // 1 ページあたりに含めるデータの数
    const itemsPerPage = tableData.itemsPerPage

    // フィルタリング
    const filtering = []
    filtering.push('deletedAt IS NULL')

    if (!_.isEmpty(this.state.tableData.filtering.title)) {
      let filteringTitle = this.state.tableData.filtering.title
      // eslint-disable-next-line
      if (filteringTitle.match(/\,/)) {
        filteringTitle = ''
      }

      // タイトルで検索する場合
      filtering.push(`title LIKE "%${filteringTitle}%"`)
    }

    const categoryId = this.state.tableData.filtering.categoryId
    if (_.isNumber(categoryId) || !_.isEmpty(categoryId)) {
      filtering.push(`categoryId = ${categoryId}`)
    }

    const mediumId = this.state.tableData.filtering.mediumId
    if (_.isNumber(mediumId) || !_.isEmpty(mediumId)) {
      filtering.push(`mediumId = ${mediumId}`)
    }

    // ソートを配列に変換
    const sorting = _.map(tableData.sorting, (value, key) => {
      const prefix = _.isEqual(value, 'desc') ? '-' : ''
      return prefix.concat(key)
    })

    const query = {
      currentPage,
      itemsPerPage,
      filtering,
      sorting,
    }

    logger.debug('get request query', { query })

    return query
  }

  render() {
    const publishDatetimeLabel = this.state.publishDatetime.format(PUBLISH_DATETIME_LABEL_FORMAT)

    // status valid for descripiton
    const isDescriptionValid = this.state.isDescriptionValid
    // status description modified
    const isDescriptionModified = this.state.isDescriptionModified
    // 必須項目が全て埋まっているかどうか
    const isFormValid = this.state.isFormValid
    // フォームに更新があるかどうか
    const isFormModified = this.state.isFormModified
    // 公開日付に更新があるかどうか
    const isPublishDatetimeModified = this.state.isPublishDatetimeModified
    // 更新・保存ボタンの状態 (関連記事のみ更新したい場合も考慮して icChecked も判定に入れる)
    const disabled =
      !isFormValid || !isDescriptionValid || (!isFormModified && !isPublishDatetimeModified && !isDescriptionModified)
    const tableData = this.state.tableData

    return (
      <Modal
        className="MatomeEditModal"
        size="fullscreen"
        closeIcon
        open={this.props.open}
        onClose={this.handleModalClose}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{_.isEmpty(this.state.matome) ? '特集の作成' : '特集の編集'}</Modal.Header>

        {/* ローディング */}
        <Dimmer active={this.state.isBusy} inverted>
          <Loader />
        </Dimmer>

        <Modal.Content>
          {/* API エラーメッセージ */}
          <ApiErrorMessage error={this.state.apiError} />

          <Form
            ref="form"
            noValidate
            onChange={this.handleFormChange}
            onValid={this.handleFormValid}
            onInvalid={this.handleFormInvalid}
            onValidSubmit={this.handleFormValidSubmit}
          >
            <Grid columns="2" doubling>
              <Grid.Column width={10}>
                {/* 特集のタイトル入力フィールド */}
                <Form.Input name="title" label="タイトル" placeholder="特集のタイトルを入力してください" required />

                {/* サマリー入力フィールド */}
                <Form.Field
                  name="description"
                  label="サマリー"
                  placeholder="サマリーを入力してください"
                  minRows={10}
                  maxRows={1000}
                  rows={1000}
                  control={TextareaAutosize}
                  required
                  onChange={this.handleDescriptionChange}
                  value={this.state.description}
                  error={!this.state.isDescriptionValid}
                />

                {/* スラッグ入力フィールド */}
                <Popup
                  inverted
                  trigger={
                    <Form.Input
                      name="slug"
                      label="スラッグ"
                      placeholder="スラッグを入力してください"
                      validations={{ matchRegexp: /^[a-z0-9-]*$/i }}
                      validationErrors={{
                        matchRegexp: '英数字または - で入力してください。',
                      }}
                      errorLabel={<FormErrorLabel />}
                    />
                  }
                  content="URLの最後に表示される文字を設定できます。"
                />

                {/* 公開日時 */}
                <Form.Field required>
                  <label>公開日時</label>

                  <DateRangePicker
                    containerStyles={{ display: 'block' }}
                    drops="up"
                    singleDatePicker
                    timePicker
                    timePicker24Hour
                    locale={{
                      applyLabel: '確定',
                      cancelLabel: 'キャンセル',
                    }}
                    startDate={this.state.publishDatetime}
                    onEvent={this.handleDatePickerEvent}
                  >
                    <Input
                      required
                      value={publishDatetimeLabel}
                      icon="calendar"
                      iconPosition="left"
                      readOnly
                      placeholder="公開日時を入力してください"
                      name="publishDatetime"
                    />
                  </DateRangePicker>
                </Form.Field>
              </Grid.Column>

              <Grid.Column width={6}>
                {/* サムネイル画像 */}
                <Segment.Group>
                  <Segment>
                    <Label attached="top" color="blue" content="サムネイル" />

                    <Form.Field required>
                      <label>画像</label>

                      <MediumInput
                        mediumUrl={this.state.thumbnailImageUrlInputValue}
                        onChange={this.handleThumbnailImageInputChange}
                      />

                      <Form.Input
                        className="isHidden"
                        name="thumbnail.image.url"
                        placeholder="サムネイル画像を選択してください"
                        required
                        readOnly
                        value={this.state.thumbnailImageUrlInputValue}
                      />
                    </Form.Field>

                    <Form.Input
                      label="出典元"
                      placeholder="出典元を入力してください"
                      name="thumbnail.image.copyright.title"
                    />

                    <Form.Input
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      name="thumbnail.image.copyright.url"
                      validations="isUrl"
                      validationErrors={{ isUrl: '無効な URL です' }}
                      errorLabel={<FormErrorLabel />}
                    />
                  </Segment>

                  {/* カバー画像を別の画像に設定する場合のトグル */}
                  <Segment>
                    <Checkbox
                      toggle
                      label="カバー画像を別の画像に設定"
                      checked={this.state.isCoverImageActive}
                      onChange={this.handleCoverImageToggleChange}
                    />
                  </Segment>

                  {/* カバー画像 */}
                  <Segment
                    className={classNames({
                      isHidden: !this.state.isCoverImageActive,
                    })}
                  >
                    <Label attached="top" color="blue" content="カバー" />

                    <Form.Field required={this.state.isCoverImageActive}>
                      <label>画像</label>

                      <MediumInput
                        mediumUrl={this.state.coverImageUrlInputValue}
                        onChange={this.handleCoverImageInputChange}
                      />

                      <Form.Input
                        className="isHidden"
                        name="cover.image.url"
                        placeholder="カバー画像を選択してください"
                        required={this.state.isCoverImageActive}
                        readOnly
                        value={this.state.coverImageUrlInputValue}
                      />
                    </Form.Field>

                    <Form.Input
                      label="出典元"
                      placeholder="出典元を入力してください"
                      name="cover.image.copyright.title"
                    />

                    <Form.Input
                      label="出典元の URL"
                      placeholder="出典元の URL を入力してください"
                      name="cover.image.copyright.url"
                      validations="isUrl"
                      validationErrors={{ isUrl: '無効な URL です' }}
                      errorLabel={<FormErrorLabel />}
                    />
                  </Segment>
                </Segment.Group>
              </Grid.Column>
            </Grid>
          </Form>

          {/* 紐づいている記事一覧を表示 */}
          {!this.state.isHiddenArticlesTable && (
            <div>
              <Divider hidden />

              <label>紐付け済み記事一覧</label>

              <Message info>
                <b>{`${this.state.totalRelatedArticles} 件の紐付け済み記事があります。`}</b>
              </Message>

              <Segment loading={tableData.isBusy}>
                {/* 検索エリア */}
                <Form onValid={this.handleFormSearchValid} onInvalid={this.handleFormSearchInValid}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Input
                        name="matome-search"
                        type="text"
                        placeholder="タイトルで検索"
                        action
                        value={this.state.tableData.filtering.title}
                        onChange={this.handleTitleInputChange}
                        validations={{ matchRegexp: /^((?!,).)*$/i }}
                        validationErrors={{
                          matchRegexp: 'キーワードに不正な記号があるため検索できません',
                        }}
                        errorLabel={<FormErrorLabel />}
                      >
                        <input />

                        <Button
                          icon="search"
                          onClick={this.handleSearchButtonClick}
                          disabled={!this.state.isFormSearchValid}
                        />
                      </Input>
                    </Form.Field>

                    <Form.Field>
                      <Form.Field>
                        <CategoriesDropdown
                          categoryId={this.state.tableData.filtering.categoryId}
                          fluid={false}
                          onChange={this.handleCatgoriesDropdownChange}
                          disabled={!this.state.isFormSearchValid}
                        />
                      </Form.Field>
                    </Form.Field>

                    <Form.Field>
                      <Form.Field>
                        <MediaDropdown
                          mediumId={this.state.tableData.filtering.mediumId}
                          fluid={false}
                          onChange={this.handleMediaDropdownChange}
                          disabled={!this.state.isFormSearchValid}
                        />
                      </Form.Field>
                    </Form.Field>
                  </Form.Group>
                </Form>

                {/* API エラーメッセージ */}
                <ApiErrorMessage error={this.state.matomeArticleApiError} />

                {!_.isEmpty(this.state.tableData.articles) && (
                  <ArticleDataTable
                    articles={tableData.articles}
                    sort={tableData.sorting}
                    selectable={false}
                    currentPage={tableData.currentPage}
                    itemsPerPage={tableData.itemsPerPage}
                    totalPages={tableData.totalPages}
                    onSelectionChange={this.handleMatomeArticleDataTableSelectionChange}
                    onPageChange={this.handleMatomeArticleDataTablePageChange}
                    extColumns={[
                      {
                        label: '操作',
                        align: 'center',
                        render: item => (
                          <Button.Group>
                            <Popup
                              inverted
                              trigger={
                                <Button
                                  icon="edit"
                                  secondary
                                  onClick={event => {
                                    event.preventDefault()
                                    this.handleArticleEditButtonClick(item)
                                  }}
                                />
                              }
                              content="この記事を編集"
                            />

                            <Button
                              secondary
                              key={item.id}
                              icon="trash alternate outline"
                              onClick={event => {
                                event.preventDefault()
                                this.openDeleteModal(item)
                              }}
                            />
                          </Button.Group>
                        ),
                      },
                    ]}
                  />
                )}
              </Segment>
            </div>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button content="キャンセル" onClick={this.handleModalClose} />

          <Button
            positive
            content={_.isEmpty(this.state.matome) ? '保存' : '更新'}
            disabled={disabled}
            onClick={this.handleSaveButtonClick}
          />

          {!_.isEmpty(this.state.matome) && (
            <Button
              primary
              content="関連記事の追加"
              onClick={event => {
                event.preventDefault()
                this.openArticleAddModal()
              }}
            />
          )}
        </Modal.Actions>

        {/* 紐付け可能な記事モーダル */}
        <MatomeAddArticleModal
          loading={this.state.tableData.isBusy}
          open={this.state.isAddModalOpen}
          onAdd={this.handleAddModalAdd}
          onCancel={this.handleAddModalCancel}
          ignoreArticles={this.state.tableData.articles}
        />

        {/* タグ削除用のモーダル */}
        <Modal
          size="tiny"
          closeIcon={true}
          open={this.state.isDeleteModalOpen}
          onClose={this.handleDeleteModalCancelButtonClick}
          closeOnDimmerClick={false}
        >
          <Modal.Header>記事の削除</Modal.Header>

          {/* ローディング */}
          <Dimmer active={this.state.tableData.isBusy} inverted>
            <Loader />
          </Dimmer>

          <Modal.Content>
            {/* API エラーメッセージ */}
            <ApiErrorMessage error={this.state.matomeArticleApiError} />
            {_.get(this.state, 'deleteArticle.title')} を削除しますか？
          </Modal.Content>

          <Modal.Actions>
            <Button content="キャンセル" onClick={this.handleDeleteModalCancelButtonClick} />

            <Button negative content="削除" onClick={this.handleDeleteModalApproveButtonClick} />
          </Modal.Actions>
        </Modal>
      </Modal>
    )
  }
}

MatomeEditModal.propTypes = propTypes
MatomeEditModal.defaultPropTypes = defaultPropTypes

export default MatomeEditModal
